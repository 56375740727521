export const LOGIT_INTERCEPTS = {
    1: -0.5578797209020812,
    2: -2.3816473307447232,
    3: -3.408503139382834,
    4: -1.4508928654201572,
    5: -1.8202133158422773,
    6: -2.4613830265184764,
    7: -2.3079949843348704,
    8: -2.934665243422152,
    9: -3.8945990049912256,
    10: -3.4973214669237294,
    11: -3.1139646807925994,
    12: -2.3110148236404755,
    13: -1.6338387450749083,
    14: -2.5739540967295578,
    15: 0.0,
    16: -1.2411338661858178,
    17: -1.105254165262948,
    18: -2.220565865397232,
    19: -0.5537779446248267,
    20: -1.5567223525727913,
};
export const LOGIT_PFA = {
    1: 0.1504905442950007,
    2: 0.2531486445207378,
    3: 0.2321103324697762,
    4: 0.18713226191708446,
    5: 0.2615804517882756,
    6: 0.28597652462093764,
    7: 0.2432710227007538,
    8: 0.288969073858643,
    9: 0.36218239945488984,
    10: 0.22761681762365243,
    11: 0.16483573602534698,
    12: 0.22236679235987714,
    13: 0.22343118054005098,
    14: 0.25324711454564514,
    15: 0.2778707661218333,
    16: 0.18479111307345844,
    17: 0.16136278168925386,
    18: 0.1833797048239287,
    19: 0.2732663841768408,
    20: 0.27679359966203076,
};
export const LOGIT_NFA = {
    1: 0.44829854522257373,
    2: 0.31415566466948475,
    3: 0.29032610878400084,
    4: 0.5320375083770186,
    5: 0.3567352676676174,
    6: 0.3787506148667497,
    7: 0.29108385342486015,
    8: 0.2930170316394068,
    9: 0.23741679477916844,
    10: 0.3547806613431836,
    11: 0.42592418561193834,
    12: 0.4606426831762092,
    13: 0.4735258538040338,
    14: 0.3410753124772514,
    15: 0.4940017761967063,
    16: 0.42720006106472436,
    17: 0.4707881547790502,
    18: 0.45847099150186704,
    19: 0.4205628073099468,
    20: 0.37475352079020346,
};
export const LOGIT_IS_POS2 = {
    1: 0.03429419760004175,
    2: 0.09041359948052288,
    3: 0.23083014330300766,
    4: 0.2874169253807672,
    5: 0.21038894660290797,
    6: 0.23387176794837566,
    7: 0.3685800188509685,
    8: -0.018429043568728678,
    9: 0.20641526079920008,
    10: 0.421802212823246,
    11: 0.584613155004644,
    12: 0.3347726570486548,
    13: 0.3419735290394779,
    14: 0.2405143932563621,
    15: 0.14120384949873804,
    16: 0.047423237638579925,
    17: 0.10306224851649924,
    18: 0.36042855598351886,
    19: 0.19214520350035752,
    20: 0.07406792006773114,
};
export const LOGIT_IS_POS3 = {
    1: 0.3092282470680153,
    2: 0.4655924180505679,
    3: 0.5730561884025372,
    4: 0.5725473111008886,
    5: 0.5046797635697318,
    6: 0.3695549563348381,
    7: 0.6067212683175645,
    8: 0.21005915395134087,
    9: 0.5411383161760385,
    10: 0.8200385942630342,
    11: 0.6494667925507648,
    12: 0.5600787227660936,
    13: 0.5419822910230638,
    14: 0.4565731916308943,
    15: 0.40863655490641776,
    16: 0.31500675602167555,
    17: 0.2538973699664126,
    18: 0.6688681898395414,
    19: 0.5144414675041572,
    20: 0.5319601064873686,
};
export const LOGIT_IS_POS4 = {
    1: 0.48811138882862903,
    2: 0.6967019333927604,
    3: 0.7969444280970022,
    4: 0.8682858739862767,
    5: 0.7526893898825266,
    6: 0.6932109800729531,
    7: 0.8464371564480998,
    8: 0.6116666889341296,
    9: 1.0102250787853437,
    10: 1.0120989023512692,
    11: 1.0976098133659882,
    12: 0.9806328208591978,
    13: 0.9055205948235535,
    14: 0.7279610425229587,
    15: 0.5425059013402355,
    16: 0.6823443615601946,
    17: 0.5519879585215964,
    18: 0.9501583099495318,
    19: 0.74222452433783,
    20: 0.8196983477882641,
};
